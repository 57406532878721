import React, { useState, useEffect } from 'react'
import MaskedInput from 'react-text-mask'

import Container from './styles'
import { cpfMask, phoneMask } from '@valid/config'
import { Icons } from '@valid/components'

const masks = {
  cpf: cpfMask,
  phone: phoneMask,
}

export default (props) => {
  const {
    label,
    field,
    form,
    mask,
    multiline,
    labeltype,
    type,
    innerref,
    focus,
  } = props
  const { errors, setFieldValue } = form
  const { name } = field
  const [passwordFieldType, setPasswordFieldType] = useState(type)
  const [showPasswordButton, setShowPasswordButton] = useState(false)

  useEffect(() => {
    if (innerref && focus) innerref.current.focus()
  }, [focus, innerref])

  function handleShowPass() {
    if (passwordFieldType === 'password') setPasswordFieldType('text')
    else setPasswordFieldType('password')
  }

  function renderTextInput() {
    return (
      <div
        className={`textinput__container ${
          errors[name] ? 'textinput__container--error' : ''
        }`}
      >
        <input
          {...props}
          {...field}
          ref={innerref ? innerref : null}
          className="textinput__input"
          id={name}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />
      </div>
    )
  }

  function renderShowPasswordButton(name, value) {
    if (value.trim().length > 0) setShowPasswordButton(true)
    else setShowPasswordButton(false)
    setFieldValue(name, value)
  }

  function renderPasswordInput() {
    return (
      <div
        className={`textinput__container ${
          errors[name] ? 'textinput__container--error' : ''
        }`}
      >
        <input
          {...props}
          {...field}
          ref={innerref ? innerref : null}
          className="textinput__input"
          id={name}
          onChange={(e) => renderShowPasswordButton(name, e.target.value)}
          type={passwordFieldType}
        />
        {showPasswordButton && (
          <button
            type="button"
            className="textinput__pass_show"
            onClick={handleShowPass}
            tabIndex="-1"
          >
            {passwordFieldType === 'password' ? (
              <Icons icon="eyeShow" />
            ) : (
              <Icons icon="eyeHide" />
            )}
          </button>
        )}
      </div>
    )
  }

  function renderTextArea() {
    return (
      <div
        className={`textinput__container ${
          errors[name] ? 'textinput__container--error' : ''
        }`}
      >
        <textarea
          {...props}
          {...field}
          ref={innerref ? innerref : null}
          className="textinput__input"
          id={name}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />
      </div>
    )
  }

  function renderMaskedInput() {
    return (
      <div
        className={`textinput__container ${
          errors[name] ? 'textinput__container--error' : ''
        }`}
      >
        <MaskedInput
          {...props}
          {...field}
          ref={innerref ? innerref : null}
          mask={masks[mask]}
          inputMode="numeric"
          pattern="[0-9]*"
          className="textinput__input"
          id={name}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />
      </div>
    )
  }

  return (
    <Container>
      <div className="textinput">
        <div className={labeltype === 'inline' ? 'textinput--inline' : ''}>
          <label
            className={`textinput__label ${
              errors[name] ? 'textinput__label--error' : ''
            }`}
            htmlFor={name}
          >
            {label}
          </label>
          {!mask ? (
            <React.Fragment>
              {!multiline ? (
                <React.Fragment>
                  {type === 'password'
                    ? renderPasswordInput()
                    : renderTextInput()}
                </React.Fragment>
              ) : (
                renderTextArea()
              )}
            </React.Fragment>
          ) : (
            renderMaskedInput()
          )}
        </div>

        <div className="textinput__error">{errors[name]}</div>
      </div>
    </Container>
  )
}
