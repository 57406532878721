import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialProvider } from '@material-ui/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as Sentry from '@sentry/browser'
import { ConnectedRouter } from 'connected-react-router'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Helmet } from 'react-helmet'

import { store, persistor, history } from 'store'
import { theme, materialTheme } from '@valid/config'
import './index.css'
import Routes from './routes'

moment.locale('pt-br')
const favicon = `assets/${process.env.REACT_APP_THEME}/favicon.ico`

if (['production', 'staging'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn:
      'https://c3fc357150744cba974894c0a8818b22@o277483.ingest.sentry.io/5282104',
  })
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <MaterialProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar
              newestOnTop
            />
            <ConnectedRouter history={history}>
              <Helmet>
                <link
                  id="favicon"
                  rel="icon"
                  href={favicon}
                  type="image/x-icon"
                />
              </Helmet>
              <Routes />
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </MaterialProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
