import styled from 'styled-components'

export default styled.div`
  width: 100%;
  margin-bottom: 1rem;

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .textinput {
    width: 100%;

    &__label {
      display: block;
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 0.3rem;

      &--error {
        color: ${(props) => props.theme.palette.error};
      }
    }

    &__container {
      border: 1px solid ${(props) => props.theme.palette.gray[400]};
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      background-color: white;
      width: 100%;

      &--error {
        border-color: ${(props) => props.theme.palette.error};
      }
    }

    &__input,
    &__area {
      width: 100%;
      outline: none;
      padding: 1rem;
      font-size: 1.2rem;
      border: 0;
      resize: none;
    }

    &__input {
      height: 3.5rem;
      padding: 0 1rem;
    }

    &__pass_show {
      background-color: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
      padding: 0 0.5rem;
      padding-top: 0.5rem;
    }

    &__error {
      text-align: right;
      font-weight: 600;
      font-size: 1.1rem;
      margin-top: 0.3rem;
      color: ${(props) => props.theme.palette.error};
    }

    &--inline {
      display: flex;
      align-items: center;

      .textinput__label {
        margin-right: 1rem;
      }
    }
  }
`
