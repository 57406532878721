import styled from 'styled-components'

export default styled.div`
  width: 100%;
  margin-bottom: 1rem;

  .input {
    width: 100%;

    &__container {
      &__check {
        background-color: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      &__label {
        display: block;
        font-weight: 600;
        font-size: 1.2rem;
        margin: 1rem;
        cursor: pointer;

        &--error {
          color: ${(props) => props.theme.palette.error};
        }
      }

      &--disabled {
        opacity: 0.4;
        cursor: default !important;
      }
    }

    &__error {
      text-align: right;
      font-weight: 600;
      font-size: 1.1rem;
      margin-top: 0.3rem;
      color: ${(props) => props.theme.palette.error};
    }
  }
`
