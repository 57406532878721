import styled from 'styled-components'

export default styled.div`
  width: 100%;
  margin-bottom: 1rem;

  .select {
    width: 100%;

    &__label {
      display: block;
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 0.3rem;

      &--error {
        color: ${(props) => props.theme.palette.error};
      }
    }

    &__container {
      width: 100%;
      border: 1px solid ${(props) => props.theme.palette.gray[400]};
      border-radius: 0.4rem;

      &--error {
        border-color: ${(props) => props.theme.palette.error};
      }
    }

    &__input {
      width: 100%;
      outline: none;
      padding: 0;
      font-size: 1.2rem;
      border: 0;
      resize: none;
    }

    &__error {
      text-align: right;
      font-weight: 600;
      font-size: 1.1rem;
      margin-top: 0.3rem;
      color: ${(props) => props.theme.palette.error};
    }

    &--inline {
      display: flex;
      align-items: center;

      .select__label {
        margin-right: 1rem;
      }
    }
  }
`
