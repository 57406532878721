import React from 'react'
import { Switch } from 'react-router-dom'

import DefaultLayout from 'ui/layouts/Default'
import SignedInLayout from 'ui/layouts/SignedIn'
import ProtectedRoute from 'routes/ProtectedRoute'

import Login from 'ui/views/Login'
import Dashboard from 'ui/views/Dashboard'
import Link from 'ui/views/Link'

export default () => {
  return (
    <Switch>
      <DefaultLayout exact path="/" component={Login} />
      <ProtectedRoute
        path="/dashboard"
        parent={SignedInLayout}
        child={Dashboard}
      />
      <ProtectedRoute path="/link" parent={SignedInLayout} child={Link} />

      <DefaultLayout path="/" component={Login} />
    </Switch>
  )
}
