import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 4.8rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.gray[500]};
  margin-bottom: 4rem;
`

export const SubTitle = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.palette.gray[500]};
  margin-top: 0.5rem;
`
