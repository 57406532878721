import React from 'react'
import { Route } from 'react-router-dom'

import Container from './styles'
import valid_logo from 'assets/valid_logo.svg'

const logo = `assets/${process.env.REACT_APP_THEME}/logo.png`

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Container>
          <main>
            <div className="brand">
              <img src={logo} alt="" className="brand__logo" />
              <img src={valid_logo} alt="" className="brand__system" />
            </div>
            <Component {...matchProps} />
          </main>
        </Container>
      )}
    />
  )
}
