import styled from 'styled-components'

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 100%;
    max-width: 80rem;

    .link_input {
      border: 1px solid ${(props) => props.theme.palette.gray[500]};
      height: 5rem;
      border-radius: 0.8rem;
      font-size: 1.4rem;
      padding: 1.4rem;
      white-space: nowrap;
      overflow: auto;
      color: ${(props) => props.theme.palette.primary};
    }

    .hidden {
      opacity: 0;
    }
  }
`
