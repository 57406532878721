export const LINK_REQUEST = 'LINK_REQUEST'
export const LINK_FETCH = 'LINK_FETCH'
export const LINK_SUCCESS = 'LINK_SUCCESS'
export const LINK_FAILURE = 'LINK_FAILURE'

const initialState = {
  list: [],
  url: '',
  fetch_loading: false,
  loading: false,
  error: '',
  success: false,
}

export default function link(state = initialState, { type, payload }) {
  switch (type) {
    case LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case LINK_FETCH:
      return {
        ...state,
        fetch_loading: true,
        error: '',
      }
    case LINK_SUCCESS:
      return {
        list: payload.list ? payload.list : state.list,
        url: payload.url ? payload.url : state.url,
        loading: false,
        error: '',
        success: true,
      }
    case LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        success: false,
      }
    default:
      return state
  }
}

export function generate(payload) {
  return {
    type: LINK_REQUEST,
    payload,
  }
}

export function fetch(payload) {
  return {
    type: LINK_FETCH,
    payload,
  }
}
