import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

import createRootReducer from './reducers'
import sagas from './sagas'

const persistConfig = {
  key: `${process.env.REACT_APP_SERVICE}_storage`,
  storage,
  whitelist: ['user'],
}

export const history = createBrowserHistory()

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history),
)

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
)

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export { store, persistor }
