import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'

const Tabs = styled.div`
  position: relative;
  margin-bottom: 3.8rem;

  .tab {
    background-color: transparent;
    border: 0;
    outline: none;
    font-size: 1.4rem;
    color: ${(props) => props.theme.palette.gray[800]};
    padding: 0 2rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s;

    &--active {
      font-weight: 700;
    }
  }

  .marker {
    position: absolute;
    left: 0;
    height: 0.2rem;
    width: 0;
    background-color: ${(props) => props.theme.palette.primary};
    bottom: -0.8rem;
    transition: 0.3s;
    border-radius: 0.4rem;
  }
`

export default function ({ tabs, onChange, selectedIndex = 0 }) {
  const [tabsState, setTabsState] = useState(tabs)
  const markerRef = useRef()

  function indicator(e) {
    const marker = markerRef.current
    marker.style.left = e.offsetLeft + 'px'
    marker.style.width = e.offsetWidth + 'px'
  }

  const handleTabChange = useCallback(
    (e, index) => {
      const newTabsState = tabs.map((item, i) => {
        item.active = false
        if (i === index) item.active = true
        return item
      })
      setTabsState(newTabsState)
      onChange(index)
      indicator(e)
    },
    [onChange, tabs],
  )

  useEffect(() => {
    const allTabs = document.querySelectorAll('#tabs .tab')
    handleTabChange(allTabs[selectedIndex], selectedIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Tabs>
      <div className="marker" ref={markerRef} />
      <div id="tabs">
        {tabsState &&
          tabsState.length > 0 &&
          tabsState.map((tab, index) => (
            <button
              type="button"
              key={index}
              className={`tab ${tab.active ? 'tab--active' : ''}`}
              onClick={(e) => handleTabChange(e.target, index)}
            >
              {tab.label}
            </button>
          ))}
      </div>
    </Tabs>
  )
}
