export default {
  inova: {
    primary: '#cb184b',
    contrast: 'white',
  },
  tosalvo: {
    primary: '#17a2b8',
    contrast: 'white',
  },
}
