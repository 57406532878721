import styled from 'styled-components'

export default styled.ul`
  list-style: none;
  width: 100%;
  text-align: center;
  margin-top: 2rem;

  li {
    display: inline-block;

    button {
      background-color: transparent;
      border: 0;
      outline: none;
      color: ${(props) => props.theme.palette.gray[800]};
      font-size: 1.4rem;
      padding: 0.5rem 1rem;
      font-weight: 700;
      cursor: pointer;
      border-radius: 0.4rem;

      &:hover {
        background-color: ${(props) => props.theme.palette.gray[200]};
      }

      &.selected {
        background-color: ${(props) => props.theme.palette.gray[800]};
        color: white;
      }
    }

    .ellipsis {
      color: ${(props) => props.theme.palette.gray[800]};
      font-size: 1.4rem;
      padding: 0.5rem 1rem;
      font-weight: 700;
    }
  }
`
