import styled from 'styled-components'

export default styled.h1`
  color: black;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`

export const OnboardSubtitle = styled.h3`
  color: ${(props) => props.theme.palette.gray[500]};
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
`

export const TargetSubtitle = styled.h3`
  color: ${(props) => props.theme.palette.dark};
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
  background-color: #fafafa;
  padding: 20px;
`