import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip, IconButton } from '@material-ui/core'
import styled from 'styled-components'

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    fontSize: 11,
    top: -10,
  },
}))(Tooltip)

const StyledIconButton = styled(IconButton)`
  span {
    svg {
      width: 2.2rem;
      height: 2.2rem;
      color: ${(props) => props.theme.palette.primary};
    }
  }

  &.disabled {
    span {
      svg {
        color: ${(props) => props.theme.palette.gray[800]};
      }
    }
  }
`

export default function CustomizedTooltips({
  children,
  title,
  disabled,
  onClick,
}) {
  return (
    <CustomTooltip title={title} onClick={!disabled ? onClick : null}>
      <StyledIconButton className={`${disabled ? 'disabled' : ''}`}>
        {children}
      </StyledIconButton>
    </CustomTooltip>
  )
}
