import { call, put } from 'redux-saga/effects'

import { LINK_SUCCESS, LINK_FAILURE } from 'store/reducers/link'
import { get } from 'service/api'

export function* handleGenerateLink(action) {
  const { token } = action.payload

  const res = yield call(get, {
    url: '/signatures/create',
    token,
  })

  if (res.status === 200) {
    yield put({
      type: LINK_SUCCESS,
      payload: { url: res.data },
    })
  } else {
    yield put({
      type: LINK_FAILURE,
    })
  }
}
