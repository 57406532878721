import styled from 'styled-components'

import background from 'assets/background.png'

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url(${background}) no-repeat top right;

  main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .brand {
    margin-right: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__logo {
      width: 22rem;
      margin-bottom: 2rem;
    }

    &__system {
      width: 25rem;
      margin-bottom: 4rem;
    }
  }
`
