import { all, takeLatest } from 'redux-saga/effects'

import { AUTH_REQUEST } from 'store/reducers/auth'
import { USER_LOGOUT } from 'store/reducers/user'
import { LINK_REQUEST } from 'store/reducers/link'
import { DASHBOARD_REQUEST } from 'store/reducers/dashboard'

import { handleLogin, handleLogout } from './auth'
import { handleGenerateLink } from './link'
import { handleFetchPage } from './dashboard'

export default function* root() {
  yield all([
    takeLatest(AUTH_REQUEST, handleLogin),
    takeLatest(USER_LOGOUT, handleLogout),
    takeLatest(LINK_REQUEST, handleGenerateLink),
    takeLatest(DASHBOARD_REQUEST, handleFetchPage),
  ])
}
