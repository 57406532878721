import React from 'react'
import { usePagination } from '@material-ui/lab/Pagination'

import Container from './styles.js'

export default function ({ pages, onChange }) {
  const { items } = usePagination({ count: pages, onChange })

  return (
    <Container>
      {items.map(({ page, type, selected, disabled, ...item }, index) => {
        let children = null

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = <span className="ellipsis">...</span>
        } else if (type === 'page') {
          children = (
            <button
              type="button"
              className={`${selected ? 'selected' : ''}`}
              {...item}
            >
              {page}
            </button>
          )
        } else if (type === 'previous') {
          if (!disabled) {
            children = (
              <button type="button" {...item}>
                Anterior
              </button>
            )
          }
        } else if (type === 'next') {
          if (!disabled) {
            children = (
              <button type="button" {...item}>
                Próxima
              </button>
            )
          }
        } else {
          children = (
            <button type="button" {...item}>
              {type}
            </button>
          )
        }

        return <li key={index}>{children}</li>
      })}
    </Container>
  )
}
