import React, { useEffect, useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

import Container, { RowButton, DocumentModal, ResultModal } from './styles'
import {
  Table,
  TableContainer,
  Pagination,
  Icons,
  Title,
} from '@valid/components'
import { fetch } from 'store/reducers/dashboard'
import { maskCpfCnpj } from '@valid/config'
import { get } from 'service/api'
import { avatar } from 'assets/default_avatar.jpg'

export default function () {
  const { token } = useSelector((state) => state.user)
  const dashboard = useSelector((state) => state.dashboard)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [docModalOpen, setDocModalOpen] = useState(false)
  const [resModalOpen, setResModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [documentUrl, setDocumentUrl] = useState('')
  const [result, setResult] = useState('')

  const { list, pages, loading } = dashboard

  const handleFetchPage = useCallback(
    async (number) => {
      dispatch(fetch({ token, page: number }))
    },
    [dispatch, token],
  )

  useEffect(() => {
    handleFetchPage(page)
  }, [handleFetchPage, page])

  function handlePageChange(_, number) {
    setPage(number)
  }

  function returnColorComponent(color) {
    if (color === 'green') return <Icons icon="check" />
    else if (color === 'yellow') return <Icons icon="info" />
    else if (color === 'red') return <Icons icon="error" />
    else return null
  }

  async function fetchDocUrl(id, side) {
    setModalLoading(true)

    const res = await get({
      url: `/dashboard/registers/${id}/document/${side}`,
      token,
    })

    setModalLoading(false)
    if (res.status === 200) {
      setDocumentUrl(res.data.url)
    }
  }

  async function fetchResultData(id) {
    setModalLoading(true)

    const res = await get({
      url: `/dashboard/registers/${id}`,
      token,
    })

    setModalLoading(false)
    if (res.status === 200) {
      setResult(res.data)
    }
  }

  function handleOpenModal(type, id, side) {
    if (type === 'doc') {
      setDocModalOpen(true)
      fetchDocUrl(id, side)
    } else if (type === 'res') {
      setResModalOpen(true)
      fetchResultData(id)
    }
  }

  function handleCloseModal() {
    setDocModalOpen(false)
    setResModalOpen(false)
  }

  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, x: 30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Title>Dashboard</Title>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Documento</th>
                <th>Data cadastro</th>
                <th>OCR Documento</th>
                <th>Liveness</th>
                <th>Facematch</th>
                <th>Quiz</th>
                <th>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item) => (
                <tr key={item.id}>
                  <td>{item.uuid}</td>
                  <td>
                    {returnColorComponent(item.cpf.color)}
                    {maskCpfCnpj(item.cpf.value)}
                  </td>
                  <td>{item.register_date}</td>
                  <td className="row center">
                    {item.document_front && (
                      <RowButton
                        onClick={() => handleOpenModal('doc', item.id, 'front')}
                      >
                        {returnColorComponent(item.document_front)}
                        <span>Frente</span>
                      </RowButton>
                    )}
                    {item.document_back && (
                      <RowButton
                        onClick={() => handleOpenModal('doc', item.id, 'back')}
                      >
                        {returnColorComponent(item.document_back)}
                        <span>Verso</span>
                      </RowButton>
                    )}
                  </td>
                  <td>
                    {item.liveness && returnColorComponent(item.liveness)}
                  </td>
                  <td>
                    {item.facematch && returnColorComponent(item.facematch)}
                  </td>
                  <td>{item.quiz && returnColorComponent(item.quiz)}</td>
                  <td className="centery">
                    <RowButton onClick={() => handleOpenModal('res', item.id)}>
                      {returnColorComponent(item.result)}
                    </RowButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination pages={pages} onChange={handlePageChange} />
      </motion.div>

      <DocumentModal open={docModalOpen} onClose={handleCloseModal}>
        {modalLoading && (
          <div className="modal_loading">
            <CircularProgress />
          </div>
        )}
        {!modalLoading && (
          <img src={documentUrl} alt="" className="modal_document" />
        )}
      </DocumentModal>

      <ResultModal open={resModalOpen} onClose={handleCloseModal}>
        {modalLoading && (
          <div className="modal_loading">
            <CircularProgress />
          </div>
        )}
        {!modalLoading && result && (
          <div className="body">
            <div className="left-section">
              <img
                className="image"
                src={result.profile_picture ? result.profile_picture : avatar}
                alt=""
              />
            </div>
            <div className="right-section">
              <p className="name">{result.name}</p>

              <p className="checklist-title">Checklist</p>
              {result.description &&
                result.description.length > 0 &&
                result.description.map((item, i) => (
                  <div className="checklist-item" key={i}>
                    <b>{item.title}</b>
                    <span>{item.value}</span>
                    {item.color && returnColorComponent(item.color)}
                  </div>
                ))}
            </div>
          </div>
        )}
      </ResultModal>

      {loading && (
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
      )}
    </Container>
  )
}
