import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'

import Container from './styles'
import { Title, Button, ButtonsContainer } from '@valid/components'
import { get } from 'service/api'

export default function () {
  const { token } = useSelector((state) => state.user)
  const [linkCopied, setLinkCopied] = useState(false)
  const [copyEnabled, setCopyEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(null)

  async function handleGenerateLink() {
    setLoading(true)

    const res = await get({
      url: '/signatures/create',
      token,
    })

    setLoading(false)

    if (res.status === 200) {
      setUrl(res.data)
      setCopyEnabled(true)
    }
  }

  function copyLink() {
    const el = document.getElementById('url')
    el.value = url.url
    el.select()
    document.execCommand('copy')
    setLinkCopied(true)

    setTimeout(() => {
      setLinkCopied(false)
    }, 3000)
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: 30 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <Container>
        <div className="content">
          <Title>Link externo</Title>

          <div className="link_container">
            <p className="link_input">{url && url.url}</p>
            <input type="text" id="url" className="hidden" readOnly />

            <ButtonsContainer>
              <Button
                variant="primary"
                onClick={handleGenerateLink}
                loading={loading}
              >
                Gerar novo link
              </Button>

              <Button
                onClick={copyEnabled ? copyLink : null}
                variant={copyEnabled ? 'primary' : ''}
              >
                {!linkCopied ? 'Clique para copiar o link' : 'Link copiado!'}
              </Button>
            </ButtonsContainer>
          </div>
        </div>
      </Container>
    </motion.div>
  )
}
