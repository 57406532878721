import React, { useState } from 'react'
import styled from 'styled-components'

import Icons from '../Icons'

const Container = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
`

export default function ({ item_id, selected = false, onSelect }) {
  const [isSelected, setIsSelected] = useState(selected)

  function handleSelect() {
    const newState = !isSelected
    setIsSelected(newState)
    onSelect(newState, item_id)
  }

  return (
    <Container type="button" onClick={handleSelect}>
      <Icons icon={`${isSelected ? 'checkboxChecked' : 'checkbox'}`} />
    </Container>
  )
}
