import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import user from './user'
import link from './link'
import dashboard from './dashboard'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    link,
    dashboard,
  })
