export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const FORM_RESET = 'FORM_RESET'

const initialState = {
  username: '',
  loading: false,
  error: '',
  success: false,
}

export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case AUTH_REQUEST:
      return {
        ...state,
        username: payload.username,
        loading: true,
        error: '',
      }
    case AUTH_SUCCESS:
      return {
        ...initialState,
        success: true,
      }
    case AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        success: false,
      }
    case FORM_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export function login(form) {
  return {
    type: AUTH_REQUEST,
    payload: form,
  }
}

export function formReset() {
  return {
    type: FORM_RESET,
  }
}
