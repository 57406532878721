import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Container, { CustomNavLink, TopMenuItem } from './styles'
import { logout } from 'store/reducers/user'
import { Icons, Popover } from '@valid/components'

const logo_min = `assets/${process.env.REACT_APP_THEME}/logo_min.png`

export default ({ component: Component, ...rest }) => {
  const { account } = useSelector((state) => state.user)
  const [menuEl, setMenuEl] = useState(null)

  const dispatch = useDispatch()
  const menuOpen = Boolean(menuEl)

  function handleLogout() {
    dispatch(logout())
  }

  const handleMenuButtonClick = (event) => {
    setMenuEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuEl(null)
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          <Container>
            <nav className="menu">
              <div className="menu__content">
                <img src={logo_min} alt="Logo" className="menu__logo" />

                <ul>
                  <li>
                    <CustomNavLink to="/dashboard">
                      <Icons icon="dashboard" /> Dashboard
                    </CustomNavLink>
                    <CustomNavLink to="/link">
                      <Icons icon="link" /> Link externo
                    </CustomNavLink>
                  </li>
                </ul>
              </div>

              <div className="top_menu">
                <div>
                  <TopMenuItem onClick={handleMenuButtonClick}>
                    {account.name && account.name.split(' ')[0]}
                    <Icons icon="menu" />
                  </TopMenuItem>

                  <Popover
                    open={menuOpen}
                    anchorEl={menuEl}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <button type="button" onClick={handleLogout}>
                      Sair
                    </button>
                  </Popover>
                </div>
              </div>
            </nav>

            <div className="content">
              <Component {...matchProps} />
            </div>
          </Container>
        </motion.div>
      )}
    />
  )
}
