import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import { serviceUrl } from '@valid/config'
import { history } from 'store'

import axios from './axiosCustom'

export const get = async ({ url, token }) => {
  return await service('GET', url, token)
}

export const post = async ({ url, token, data }) => {
  return await service('POST', url, token, data)
}

export const apiPut = async ({ url, token, data }) => {
  return await service('PUT', url, token, data)
}

const service = async (method, url, token, data = null) => {
  if (token) {
    const decoded = jwtDecode(token)
    const current_time = Date.now() / 1000

    if (decoded.exp < current_time) {
      toast.error('Seu acesso expirou.')
      return history.replace('/')
    } else {
      try {
        const res = await axios({
          method: method,
          url: `${serviceUrl[process.env.REACT_APP_ENV]}${url}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data,
        })
        return {
          status: res.status,
          data: res.data,
        }
      } catch (e) {
        return {
          status: e.response.status,
          detail: e.response.data.detail,
        }
      }
    }
  } else {
    toast.error('Acesso não autorizado!')
  }
}
