import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import background from 'assets/background_in.png'

export default styled.div`
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.palette.gray[800]};
    background-image: url(${background});
    background-blend-mode: overlay;
    background-position: bottom right;
    background-repeat: no-repeat;

    &__logo {
      height: 4rem;
      margin-left: 2rem;
    }

    &__list {
      li {
        transition: 0.1s all ease-in;

        &:hover {
          transform: translateX(1rem) scale(1.1);
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-left: 4rem;
    }
  }

  .top_menu {
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
  }

  .content {
    margin: 2rem;
  }
`

export const CustomNavLink = styled(NavLink)`
  font-size: 1.4rem;
  color: white;

  text-decoration: none;
  display: inline-block;
  padding: 2rem 1rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    vertical-align: text-bottom;
  }
`

export const TopMenuItem = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  color: white;
  margin-left: 3rem;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }
`
