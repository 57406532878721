export default {

  local: 'https://192.168.50.107:5000',
  staging: 'https://dev.valid.inovamind.app',
  production: 'https://valid.inovamind.app',
}

// const urls = {
//   inova: {
//     dashboard: {
//       development: 'http://0.0.0.0:5000',
//       staging: 'https://valid.inovamind.app',
//       production: 'https://api.regtech.inovamind.app',
//     },
//     onboard: {
//       development: 'http://0.0.0.0:5000',
//       staging: 'https://valid.inovamind.app',
//       production: 'https://api.regtech.inovamind.app',
//     },
//   },
//   tosalvo: {
//     dashboard: {
//       development: 'http://0.0.0.0:5000',
//       staging: 'https://valid.inovamind.app',
//       production: 'https://api.regtech.inovamind.app',
//     },
//     onboard: {
//       development: 'http://0.0.0.0:5000', 
//       staging: 'https://valid.inovamind.app',
//       production: 'https://api.regtech.inovamind.app',
//     },
//   },
// }

// export default urls[process.env.REACT_APP_THEME][process.env.REACT_APP_SERVICE][
//   process.env.REACT_APP_ENV
// ]
