import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export default styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    width: ${(props) => (props.width ? props.width : 'auto')};
    padding: 2rem;
    border-radius: 0.4rem;
  }

  .modal_title {
    font-size: 1.8rem;
    font-weight: 700;
    color: black;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .modal_text {
    font-size: 1.4rem;
    color: ${(props) => props.theme.palette.gray[700]};
    margin-bottom: 2rem;
    font-weight: 600;
  }

  .modal_hint {
    background-color: #fff3cd;
    color: #b55e00;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1.4rem;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;

    &__icon {
      color: #b55e00;
      margin-right: 1rem;
      font-size: 2.5rem;
    }

    p {
      line-height: 2rem;
      text-align: left;
      margin-left: 1rem;
      text-align: center;
    }
  }
`
