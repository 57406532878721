import styled from 'styled-components'

export const TableContainer = styled.div`
  color: ${(props) => props.theme.palette.gray[900]};
  border: 1px solid ${(props) => props.theme.palette.gray[500]};
  border-radius: 0.4rem;
  background-color: white;
  margin-bottom: 2rem;

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    color: ${(props) => props.theme.palette.gray[800]};
    padding: 2rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
  }

  &.deactivated {
    color: ${(props) => props.theme.palette.gray[500]};

    h3 {
      color: ${(props) => props.theme.palette.gray[500]};
    }
  }
`

export default styled.table`
  border-collapse: collapse;
  width: 100%;

  thead {
    tr {
      th {
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        padding: 1.5rem 0;
        text-align: center;
        border-bottom: 1px solid ${(props) => props.theme.palette.gray[500]};
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: ${(props) => props.theme.palette.gray[100]};
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.palette.gray[300]};
      }

      td {
        padding: 0.5rem 1rem;
        text-align: center;
        font-size: 1.2rem;

        svg {
          vertical-align: middle;
          vertical-align: middle;
          margin-right: 0.5rem;
          margin-bottom: 0.4rem;
        }

        &.no_results {
          font-size: 1.2rem;
          padding: 2rem;
          font-weight: 600;
          color: ${(props) => props.theme.palette.gray[600]};
        }

        &.row {
          display: flex;
        }

        &.center {
          justify-content: center;
        }
      }

      &.deactivated {
        color: ${(props) => props.theme.palette.gray[600]};
      }
    }
  }
`
