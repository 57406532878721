import styled from 'styled-components'

export default styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 45rem;

  .input {
    padding: 0 2rem;
    font-size: 1.2rem;
    border: 0;
    outline: none;
    width: 100%;
    height: 4rem;
  }

  .searchbar__action {
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    svg {
      color: ${(props) => props.theme.palette.gray[600]};
    }
  }

  svg {
    color: ${(props) => props.theme.palette.gray[400]};
    margin-right: 1.5rem;
    height: 26px;
    width: 26px;
  }
`
