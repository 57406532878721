export const validation = {
  required: 'Campo obrigatório',
  invalidCpf: 'CPF inválido',
  invalidCnpj: 'CNPJ inválido',
  invalidEmail: 'Email inválido',
  invalidPassword: 'Senha inválida',
  invalidPhone: 'Número inválido',
  passwordHint:
    'A senha precisa ter pelo menos 1 letra maiuscula, 1 minúscula, 1 número e um caracter especial, no mínimo 10 caracteres e caracteres iguais não podem se repetir por mais de 3 vezes',
  passwordNotMatch: 'As senhas devem ser iguais',
  companySelected: 'Empresa já selecionada',
}

// TODO: Mapear status de mensagens de telas
const response_messages = {
  auth: {
    login: {
      400: 'Email / Nome de usuário ou email inválidos',
    },
    password: {
      401: 'Token de recuperação inválido',
      400: 'A nova senha deve ser diferente da atual',
    },
  },
  signature: {
    verify: {
      401: 'Link expirado',
      400: 'Link inválido',
    },
  },
}

export function returnResponseMessage(resource, action, status) {
  if (status === 500)
    return 'Houve um erro interno no servidor. Tente novamente mais tarde.'
  return response_messages[resource][action][status]
}
