// import React from 'react'
// import CircularProgress from '@material-ui/core/CircularProgress'

// import Container, { ButtonContainer } from './styles'

// export const ButtonsContainer = ButtonContainer

// export default ({ loading, disabled, children, variant, onClick, ...rest }) => {
//   return (
//     <Container>
//       <button
//         className={`button ${loading ? 'button__loading' : ''} ${
//           disabled ? 'disabled' : ''
//         } ${variant !== undefined ? `button--${variant}` : ''}`}
//         type="button"
//         {...rest}
//         onClick={!disabled ? onClick : null}
//       >
//         {loading ? <CircularProgress className="button__loader" /> : children}
//       </button>
//     </Container>
//   )
// }

import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import Container, { ButtonContainer } from './styles'

export const ButtonsContainer = ButtonContainer

export default function ({ children, fullWidth, variant, loading, onClick }) {
  return (
    <Container
      type="button"
      className={`${fullWidth && !loading ? 'full_width' : ''} ${
        variant && !loading ? variant : ''
      } ${loading ? 'loading' : ''}`}
      onClick={!loading ? onClick : null}
    >
      {loading ? <CircularProgress className="loader" /> : children}
    </Container>
  )
}
